@import '../resources/vars';
@import '../resources/mixins';

@mixin message-style($option) {
  --app-custom-msg-background: var(--coach-#{$option}-background, var(--coach-message-background));
  --app-custom-msg-align-self: var(--coach-#{$option}-align-self);
  --app-custom-msg-align-items: var(--coach-#{$option}-align-items);
  --app-custom-msg-max-width: var(--coach-#{$option}-max-width, var(--coach-message-max-width));
  --app-custom-msg-margin: var(--coach-#{$option}-margin, 0 var(--coach-#{$option}-margin-right) 0 0);
  --app-custom-msg-border-radius: var(--coach-#{$option}-border-radius);
  --app-custom-msg-border: var(--coach-#{$option}-border);
  --app-custom-msg-box-shadow: var(--coach-#{$option}-box-shadow);
  --app-custom-msg-padding: var(--coach-#{$option}-padding);
  --app-custom-msg-color: var(--coach-#{$option}-text-color);
  --app-custom-msg-font-family: var(--coach-#{$option}-font-family);
  --app-custom-msg-font-size: var(--coach-#{$option}-text-size);
  --app-custom-msg-font-style: var(--coach-#{$option}-text-style);
  --app-custom-msg-font-weight: var(--coach-#{$option}-text-weight);
  --app-custom-msg-letter-spacing: var(--coach-#{$option}-letter-spacing);
  --app-custom-msg-line-height: var(--coach-#{$option}-line-height);
  --app-custom-msg-text-align: var(--coach-#{$option}-text-align);
  --app-custom-msg-text-transform: var(--coach-#{$option}-text-transform);
  --app-custom-msg-word-break: var(--coach-#{$option}-word-break);
  --app-custom-msg-text-shadow: var(--coach-#{$option}-text-shadow);

  --app-custom-msg-link-color: var(--coach-#{$option}-link-color);
  --app-custom-msg-link-decoration: var(--coach-#{$option}-link-decoration);
  --app-custom-msg-link-weight: var(--coach-#{$option}-link-weight);
  --app-custom-msg-link-style: var(--coach-#{$option}-link-style);
  --app-custom-msg-link-hover-color: var(--coach-#{$option}-link-hover-color);
  --app-custom-msg-link-hover-decoration: var(--coach-#{$option}-link-hover-decoration);
  --app-custom-msg-link-hover-weight: var(--coach-#{$option}-link-hover-weight);
  --app-custom-msg-link-hover-style: var(--coach-#{$option}-link-hover-style);
  --app-custom-msg-link-active-color: var(--coach-#{$option}-link-active-color);
  --app-custom-msg-link-active-decoration: var(--coach-#{$option}-link-active-decoration);
  --app-custom-msg-link-active-weight: var(--coach-#{$option}-link-active-weight);
  --app-custom-msg-link-active-style: var(--coach-#{$option}-link-active-style);
  --app-custom-msg-link-visited-color: var(--coach-#{$option}-link-visited-color);
  --app-custom-msg-link-visited-decoration: var(--coach-#{$option}-link-visited-decoration);
  --app-custom-msg-link-visited-weight: var(--coach-#{$option}-link-visited-weight);
  --app-custom-msg-link-visited-style: var(--coach-#{$option}-link-visited-style);

  @include respond-to(sm, up) {
    --app-custom-msg-max-width-m: var(--coach-#{$option}-max-width-m, var(--coach-message-max-width-m, var(--coach-message-max-width)));
    --app-custom-msg-text-size-m: var(--coach-#{$option}-text-size-m, var(--coach-message-text-size-m, var(--coach-message-text-size)));
  }

  @include respond-to(lg, up) {
    --app-custom-msg-max-width-l: var(--coach-#{$option}-max-width-l, var(--coach-message-max-width-l, var(--coach-message-max-width)));
    --app-custom-msg-text-size-l: var(--coach-#{$option}-text-size-l, var(--coach-message-text-size-l, var(--coach-message-text-size)));
  }

  //Before vars
  --app-custom-msg-before-content: var(--coach-#{$option}-before-content);
  --app-custom-msg-before-width: var(--coach-#{$option}-before-width);
  --app-custom-msg-before-height: var(--coach-#{$option}-before-height);
  --app-custom-msg-before-border-radius: var(--coach-#{$option}-before-border-radius);
  --app-custom-msg-before-background: var(--coach-#{$option}-before-background);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: var(--app-custom-msg-align-items);
  
  @include message-style('message');


  &.usermsg {
    @include message-style('usermsg');
  }

  margin-bottom: var(--coach-spacer-group);
}

.header {
  display: var(--theme-coach-avatar-display);
  margin-bottom: var(--coach-spacer-avatar);
  padding: var(--coach-avatar-padding);
}

.avatar {
  align-items: var(--coach-avatar-align-items);
  display: flex;
  justify-content: var(--coach-avatar-justify-content);
}

.image {
  border-radius: var(--coach-avatar-border-radius);
  height: var(--coach-avatar-height);
  overflow: hidden;
  width: var(--coach-avatar-width);
}

.body {
  gap: var(--coach-spacer-par);
  margin: var(--app-custom-msg-margin);
  width: 100%;
  align-self: var(--app-custom-msg-align-self);
  max-width: var(--app-custom-msg-max-width);

  @include respond-to(sm, up) {
    max-width: var(--app-custom-msg-max-width-m);
  }

  @include respond-to(lg, up) {
    max-width: var(--app-custom-msg-max-width-l);
  }
}

%message {
  background: var(--app-custom-msg-background);
  border-radius: var(--app-custom-msg-border-radius);
  border: var(--app-custom-msg-border);
  box-shadow: var(--app-custom-msg-box-shadow);
  padding: var(--app-custom-msg-padding);
  color: var(--app-custom-msg-color);
  font-family: var(--app-custom-msg-font-family);
  font-size: var(--app-custom-msg-font-size);
  font-style: var(--app-custom-msg-font-style);
  font-weight: var(--app-custom-msg-font-weight);
  letter-spacing: var(--app-custom-msg-letter-spacing);
  line-height: var(--app-custom-msg-line-height);
  text-align: var(--app-custom-msg-text-align);
  text-transform: var(--app-custom-msg-text-transform);
  word-break: var(--app-custom-msg-word-break);
  text-shadow: var(--app-custom-msg-text-shadow);

  @include respond-to(sm, up) {
    font-size: var(--app-custom-msg-text-size-m);
  }

  @include respond-to(lg, up) {
    font-size: var(--app-custom-msg-text-size-l);
  }

  &::before {
    content: var(--app-custom-msg-before-content);
    width: var(--app-custom-msg-before-width);
    height: var(--app-custom-msg-before-height);
    border-radius: var(--app-custom-msg-before-border-radius);
    background: var(--app-custom-msg-before-background);
  }

  a {
    color: var(--app-custom-msg-link-color);
    text-decoration: var(--app-custom-msg-link-decoration);
    font-weight: var(--app-custom-msg-link-weight);
    font-style: var(--app-custom-msg-link-style);

    &:hover {
      color: var(--app-custom-msg-link-hover-color);
      text-decoration: var(--app-custom-msg-link-hover-decoration);
      font-weight: var(--app-custom-msg-link-hover-weight);
      font-style: var(--app-custom-msg-link-hover-style);
    }

    &:active {
      color: var(--app-custom-msg-link-active-color);
      text-decoration: var(--app-custom-msg-link-active-decoration);
      font-weight: var(--app-custom-msg-link-active-weight);
      font-style: var(--app-custom-msg-link-active-style);
    }

    &:visited {
      color: var(--app-custom-msg-link-visited-color);
      text-decoration: var(--app-custom-msg-link-visited-decoration);
      font-weight: var(--app-custom-msg-link-visited-weight);
      font-style: var(--app-custom-msg-link-visited-style);
    }
  }
}

.bubble {
  @extend %message;
}

.bubbles {
  display: flex;
  flex-direction: column;
  gap: var(--coach-spacer-par);
  max-width: var(--coach-messages-max-width);

  @include respond-to(sm, up) {
    max-width: var(--coach-messages-max-width-m, var(--coach-messages-max-width));
  }

  @include respond-to(lg, up) {
    max-width: var(--coach-messages-max-width-l, var(--coach-messages-max-width));
  }

  &.singular {
    @extend %message;
  }

  &.plural {
    > p,
    > span {
      @extend %message;
    }
  }
}
